<template>
  <v-container>
    <v-row>
      <v-col v-for="driver in driversData" :key="driver.id" cols="4">
        <v-card>
          <v-row>
            <v-col cols="9">
              <v-card-title>
                <span v-html="driver.name" />
              </v-card-title>
            </v-col>
          </v-row>
          <v-card-subtitle class="mb-2">
            Cell Phone: <span v-html="driver.cellPhone" />, Status: <span v-html="driver.status.status" />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    driversData: {
      type: Array,
      default: () => []
    }
  }
}

</script>
