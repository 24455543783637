<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog
        v-model="formOpen"
        persistent
        style="width: 80%"
        width="80%"
        min-width="600px"
      >
        <v-card style="width: 100%">
          <v-card-title v-if="editDriverData">Edit Driver</v-card-title>
          <v-card-title v-else>New Driver</v-card-title>
          <v-card-text>
            <v-container style="width: 100%">
              <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      v-model="submitData.name"
                      :rules="[rules.required]"
                    >
                      <template #label> Name<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="submitData.cellPhone"
                      :rules="[rules.required, rules.phoneRules]"
                    >
                      <template #label> Cell Phone<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Home Phone"
                      v-model="submitData.homePhone"
                      :rules="[rules.phoneRules]"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Email"
                      v-model="submitData.email"
                      :rules="[rules.emailRules]"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-select
                      v-model="submitData.statusId"
                      :items="driverStatuses"
                      :item-text="(status) => status.status"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                    <template #label> Status<red-asterisk /> </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="submitData.driverLicense"
                      :rules="[rules.required]"
                    >
                      <template #label>
                        Driver License<red-asterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label=""
                      v-model="submitData.licenseClass"
                      :rules="[rules.required]"
                    >
                      <template #label>
                        License Class<red-asterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      no-data-text="Province Not Found"
                      v-model="submitData.licenseIssuingProvinceId"
                      @input="handleProvinceInput"
                      :items="provinces"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                      <template #label>
                        License Issuing Province<red-asterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="submitData.driverLicenseExpiration"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        >
                          <template #label>
                            Driver License Expiration<red-asterisk />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="submitData.driverLicenseExpiration"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- <v-row v-if="submitData.homeAddress">
                            <v-col cols="3">
                                <v-text-field label="Address Line 1" v-model="submitData.homeAddress.addressLine1" :rules="[rules.required]"/>
                            </v-col>
                            <v-col cols="3" >
                             <v-autocomplete no-data-text="Province Not Found" label="Province" v-model="submitData.homeAddress.provinceId" @input="handleProvinceInput" :items="provinces" item-text="name" item-value="id" :rules="[rules.required]"/>
                            </v-col>
                            <v-col cols="3" >
                                <v-autocomplete no-data-text="No Cities Found" label="City" v-model="submitData.homeAddress.cityId" :items="cities" :disabled="!submitData.homeAddress || !submitData.homeAddress.provinceId" item-text="name" item-value="id" :rules="[rules.required]"/>
                            </v-col>
                            <v-col cols="3" >
                                <v-text-field label="Postal Code" v-model="submitData.homeAddress.postalCode" :rules="[rules.required, rules.postalCodeRules]"/>
                            </v-col>
                        </v-row> -->
                <v-row class="text-right">
                  <v-spacer></v-spacer>
                  <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                  <v-btn
                    v-if="editDriverData"
                    variant="outlined"
                    @click="updateDriver"
                    elevation="0"
                    >Update</v-btn
                  >
                  <v-btn v-else variant="outlined" @click="submitDriver" elevation="0"
                    >Submit</v-btn
                  >
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    // editMode: {
    //   type: Boolean,
    //   default: false
    // },
    editDriverData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    provinces: [],
    cities: [],
    // driverStatuses: [{ id: 1, status: 'Active' }, { id: 2, status: 'Inactive' }],
    driverStatuses: [],
    menu: false,
    defaultSubmitData: {
      name: '',
      driverLicense: '',
      licenseClass: '',
      driverLicenseExpiration: '',
      licenseIssuingProvinceId: null,
      email: '',
      cellPhone: '',
      homePhone: '',
      statusId: null
      // sex: '',
      // homeAddress: {
      //   addressLine1: null,
      //   postalCode: null,
      //   cityId: null,
      //   provinceId: null
      // }
      // DO NOT SUBMIT SHIFT FOR NOW
      //   shift: {
      //     shiftStartDay: '',
      //     shiftEndDay: '',
      //     shiftStartTime: '',
      //     shiftEndTime: ''
      //   }
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.',
      emailRules: (value) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Must be a valid e-mail.'
        }
        return true
      },
      postalCodeRules: (value) => {
        const pattern = /^(?:[A-Za-z]\d){3}$/
        return pattern.test(value) || 'Must be a valid postal code.'
      },
      phoneRules: (value) => {
        if (value) {
          const pattern = /^(\D*\d){10}$/
          return pattern.test(value) || 'Must be a valid phone number.'
        }
        return true
      }
    }
  }),
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    },
    editDriverData: {
      handler (value) {
        if (value) {
          this.submitData = {
            ...value,
            driverLicenseExpiration:
              value.driverLicenseExpiration.split('T')[0]
          }
          /* for (const key in this.submitData) {
            if (key === 'driverLicenseExpiration') {
              this.submitData[key] = this.editDriverData[key].split('T')[0] // datepicker bugs out when given '2024-09-26T00:00:00'
            } else if (this.editDriverData[key]) {
              this.submitData[key] = this.editDriverData[key]
            }
          } */
        } else this.submitData = { ...this.defaultSubmitData }
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.provinces.length <= 0) {
        await this.getProvinces()
      }
      if (this.driverStatuses.length <= 0) {
        await this.getStatuses()
      }
      this.resetForm()
    },
    async submitDriver () {
      if (!this.$refs.form.validate()) return
      const res = await this.$api.drivers.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Driver successfully created')
        this.$emit('submit-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Driver')
      }
    },
    async updateDriver () {
      if (!this.$refs.form.validate()) return
      const res = await this.$api.drivers.put(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Driver successfully updated')
        this.$emit('submit-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to udpate Driver')
      }
    },
    closeForm () {
      this.$refs.form.resetValidation() // this can only be here. If it's in resetForm () it causes an error when the form is being mounted
      this.resetForm()
      this.$emit('close-form')
    },
    resetForm () {
      this.submitData = JSON.parse(JSON.stringify(this.defaultSubmitData))
    },
    async getProvinces () {
      const res = await this.$api.provinces.get()
      this.provinces = res
    },
    async getCitiesByProvinceId (id) {
      const params = [{ name: 'provinceId', value: id }]
      const res = await this.$api.cities.get(null, params)
      this.cities = res
    },
    async getStatuses () {
      const res = await this.$api.drivers.get('statuses')
      this.driverStatuses = res
    },
    async handleProvinceInput (value) {
      if (value != null) {
        await this.getCitiesByProvinceId(value)
      }
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
