<template>
   <v-container class="unreactive-margins">
    <v-data-table
      :headers="headers"
      :items="driversData"
      sort-by="name"
      class="inverse-header-color"
    >
    <template #[`item.name`]="{item}">
           <strong>{{ item.name }}</strong>
      </template>
      <template #[`item.driverLicenseExpiration`]="{item}">
           <span :class="`${getDateColor(item.driverLicenseExpiration)}`">{{ formatDate(item.driverLicenseExpiration) }}</span>
      </template>
      <template #[`item.licenseIssuingProvince`]="{item}">
          {{ item.licenseIssuingProvince.shortName }}
      </template>
      <template #[`item.cellPhone`]="{item}">
          {{ formatPhone(item.cellPhone) }}
      </template>
      <template #[`item.homePhone`]="{item}">
          {{ formatPhone(item.homePhone) }}
      </template>
      <template #[`item.status`]="{item}">
        <div class="d-flex justify-center">
          <v-chip small :color="chipColor(item.status.status)">{{ item.status.status }}</v-chip>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
          <!-- <v-btn v-if="isHauler" elevation="0" x-small class="mr-1" color="info" outlined @click="viewInfo(item)">View</v-btn> -->
          <v-btn v-if="isHauler" x-small elevation="0" color="info" @click="editDriver(item)">Edit</v-btn>
          <!-- - -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { formatPhoneString } from '@/helpers/FormatHelper.js'
import moment from 'moment'

export default {
  props: {
    driversData: {
      type: Array,
      default: () => {}
    },
    companyTypeId: {
      type: Number,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isHauler () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.companyTypeId
    },
    headers () {
      const headers = []
      headers.push({ text: 'Driver Code', value: 'driverCode' })
      headers.push({ text: 'Name', value: 'name' })
      headers.push({ text: 'License Issuing Province', value: 'licenseIssuingProvince' })
      headers.push({ text: 'Driver License #', value: 'driverLicense' })
      headers.push({ text: 'License Class', value: 'licenseClass' })
      headers.push({ text: 'Driver License Expiration', value: 'driverLicenseExpiration' })
      headers.push({ text: 'Email', value: 'email' })
      headers.push({ text: 'Cell Phone', value: 'cellPhone' })
      headers.push({ text: 'Home Phone', value: 'homePhone' })
      // headers.push({ text: 'Home Address', value: 'homeAddress' })
      // headers.push({ text: 'Sex', value: 'sex' })
      // headers.push({ text: 'Shift', value: 'shift' })
      headers.push({ text: 'Status', value: 'status', align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end' })
      return headers
    }
  },
  methods: {
    viewInformation (item) {
      this.$emit('viewInformation', item)
    },
    viewInfo (item) {
      this.$emit('viewInfo', item)
    },
    editDriver (driver) {
      this.$emit('edit-driver', driver)
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    formatPhone (phone) {
      return formatPhoneString(phone)
    },
    formatDate (date) {
      return moment(date).format('YYYY-MMM-DD')
    },
    getDateColor (date) {
      // // if date equals current date or date is from the past, return red
      if (moment().diff(date, 'days') >= 0) {
        return 'error--text'
      // if date within a range of tomorrow to a month from now, then return yellow
      } else if (moment().diff(date, 'days') >= -30) {
        return 'warning--text'
      } else return ''
      // else return black
    }
  }
}
</script>
<style>
  .container.unreactive-margins {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
</style>
