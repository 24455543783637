<template>
   <drivers-template />
</template>
<script>

// Components
import DriversTemplate from '@/components/drivers/DriversTemplate.vue'

export default {
  name: 'DriversView',
  components: {
    DriversTemplate
  }
}

</script>
